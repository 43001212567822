import cx from 'classnames';
import React from 'react';

const StyledButton = ({ className, children, ...props }) => {
  className = cx(
    'py-2 px-4 bg-green-700 hover:bg-green-600 text-base text-white font-bold uppercase rounded shadow-md hover:-translate-1',
    className
  );
  return (
    <button className={className} {...props}>
      {children}
    </button>
  );
};

export default StyledButton;
