import React from 'react';

import { ReactComponent as Scale } from '../assets/icons/icon-balance-scale.svg';
import { ReactComponent as Handshake } from '../assets/icons/icon-handshake.svg';
import { ReactComponent as Map } from '../assets/icons/icon-map-signs.svg';

const Guarantee = ({ className, ...props }) => {
  return (
    <div className='flex flex-col mt-10 md:mt-16'>
      <p className='mb-2 text-4xl text-gray-800 self-center'>Our Promise</p>

      <div className='flex flex-wrap justify-center items-stretch -mx-2'>
        <div className='w-full md:w-1/2 lg:w-1/3 mt-2'>
          <div className='h-full m-2 p-4 flex flex-col overflow-hidden items-center text-center'>
            <div className='w-16 h-16 mb-4'>
              <Scale />
            </div>
            <p className='text-2xl w-full'>Apples to Apples</p>
            <p>
              Just like all other licensed funeral homes in North Carolina, City
              of Oaks Cremation, LLC is regulated by the NC Board of Funeral
              Service.
            </p>
            <br />
            <p>
              The same laws that apply to the big corporate funeral homes also
              apply to the modest independently owned firms like ours. All
              crematories in North Carolina, regardless of who owns them, are
              inspected annually by the state and must adhere to the same rules
              and laws.
            </p>
          </div>
        </div>
        <div className='w-full md:w-1/2 lg:w-1/3 mt-2'>
          <div className='h-full m-2 p-4 flex flex-col overflow-hidden items-center text-center'>
            <div className='w-16 h-16 mb-4'>
              <Handshake />
            </div>
            <p className='text-2xl w-full'>Genuine Care</p>
            <p>
              We're committed to providing families within our community
              unsurpassed service before, during and after the time of loss.
            </p>
            <br />
            <p>
              Our staff will provide clear communication to your family through
              each step as we move forward from the original phone call
              notifying us of a death to the time we deliver the urn back to
              your family. We will treat your family with the utmost respect, as
              we honor the wishes of your loved one.
            </p>
            <br />
            <p>
              We promise to make this process affordable and as endurable as
              possible.
            </p>
          </div>
        </div>
        <div className='w-full md:w-1/2 lg:w-1/3 mt-2'>
          <div className='h-full m-2 p-4 flex flex-col overflow-hidden items-center text-center'>
            <div className='w-16 h-16 mb-4'>
              <Map />
            </div>
            <p className='text-2xl w-full'>State Wide</p>
            <p>
              Our Funeral Home is located in Wake County NC; however, we provide
              our services to families throughout the entire State.
            </p>
            <br />
            <p>
              Unlike other funeral homes, we don't charge an additional mileage
              fee. Regardless of what county you live in, we can help your
              family with making simple, affordable funeral arrangements. From
              Asheville to Wilmington, we're proud to help families statewide.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guarantee;
