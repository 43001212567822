import { Link, withPrefix } from 'gatsby';
import React from 'react';

import StyledButton from './styledButton';

const Service = ({ title, slug, url, icon, price, blurb, children }) => {
  return (
    <div className='w-full md:w-1/2 lg:w-1/3 mt-2'>
      <div className='h-full m-2 p-4 flex flex-col items-center text-center'>
        <div className='w-16 h-16 mb-4'>
          <img alt='icon' src={withPrefix(icon)} />
        </div>
        <Link
          to={url}
          className='text-2xl w-full text-green-700 hover:text-green-600 hover:underline'
        >
          {title}
        </Link>
        <p>{price}</p>
        <p>{blurb}</p>
        <Link to={url}>
          <StyledButton className='mt-6'>{slug}</StyledButton>
        </Link>
      </div>
      <div className='self-center mx-auto items-center'></div>
    </div>
  );
};

export default Service;
