import cx from 'classnames';
import { Link, graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

//assets
import { ReactComponent as Handshake } from '../assets/icons/icon-handshake.svg';
import banner from '../assets/images/leafBannerSmaller.jpg';
import animatedIntroLarge from '../assets/video/animatedIntro-1080.mp4';
import thumb from '../assets/video/animatedIntroThumb.png';
import Guarantee from '../components/guarantee';
//components
import Layout from '../components/layout';
import SEO from '../components/seo';
import Service from '../components/service';
import StyledButton from '../components/styledButton';

function Hospice({ data }) {
  const services = data.services.edges;

  return (
    <Layout hasHero={false} heroSize='30rem' heroImage={banner}>
      <SEO
        title='Hospice Death Preparation'
        description='What to do when a death occurs in hospice care a guide from City of Oaks Funeral Home in Raleigh, serving all of North Carolina.'
      />
      <div className='flex flex-col mt-10 md:mt-16'>
        <div className='w-16 h-16 mb-4 self-center'>
          <Handshake />
        </div>

        <div className='font-serif font-hairline'>
          <h2 className='text-3xl md:text-4xl text-green-700'>
            What to do when your loved one passes in hospice.
          </h2>
        </div>
        <div className='flex flex-col mt-10 md:mt-16'>
          <h1 className='my-2 text-lg text-gray-800'>
            A cremation guide for hospice care from City of Oaks Cremation.
          </h1>
          <p className='py-4'>
            We have often heard that having a family member under the care of
            hospice allows time to emotionally prepare and plan for the loss of
            a loved one. It can still be challenging to stay focused on
            preparing and planning during the hospice period and at the time of
            death. City of Oaks Cremation wants to make that part of the process
            as simple and/or as bearable as possible.
          </p>
          <h3 className='my-2 text-2xl text-gray-800'>
            What to do when your loved one passes while under the care of a
            Hospice Provider
          </h3>
          <p className='py-2'>
            When your loved one passes, call the hospice facility or your
            hospice nurse directly (as instructed by your hospice provider).
            Some families choose to take this time to sit with their loved one,
            while others may feel more comfortable leaving the room. Do whatever
            is right for you.
          </p>
          <p className='py-2'>
            When your hospice nurse arrives, he or she will confirm and
            pronounce the death of your loved one. At this time, your provider
            may perform aftercare activities, such as removing medical devices,
            hygiene tasks, and preparations for transport.
          </p>
          <p className='py-2'>
            Your hospice nurse can contact City of Oaks Cremation at (919)
            438-1649 at the time of death. Your nurse will provide us with a few
            necessary details and make arrangements for our team to bring your
            loved one into our care. If you need additional time with your loved
            one, you may communicate that to your nurse. While our team is in
            route, it is a good time to remove any jewelry or personal items you
            wish to keep. You may also place items with your loved one, such as
            cards, letters, or a special blanket. Once your loved one is in our
            care, a funeral director will reach out to you to go over the next
            steps and answer any questions you may have.
          </p>
          <h3 className='my-2 text-2xl text-gray-800'>Helpful Suggestions</h3>
          <p className='py-2'>
            When notifying immediate family members of the passing of your loved
            one, enlist their help in notifying other family members and
            friends. This relieves the burden of placing and answering calls
            during a difficult time.
          </p>
          <p className='py-2'>
            Consider reaching out to City of Oaks Cremation in advance to
            discuss plans for cremation, burial, or anatomical donation. It is
            much easier to take notes and retain information when you are not in
            the midst of grief. Our funeral directors are happy to answer any
            questions you may have regarding arrangements for your loved one.
          </p>
          <p className='py-2'>
            Talk to your loved one and family about implementing a Healthcare
            Power of Attorney Document. This document is separate and unique
            from a standard power of attorney document or medical directive in
            that it includes special language that provides you with the “right
            to disposition”. This section gives you the utmost legal authority
            to choose cremation, burial, or anatomical donation for your loved
            one.
          </p>
          <p className='py-2'>
            Healthcare Power of Attorney Documents are especially helpful when
            assisting a parent of a large family, an extended relative, or even
            a close friend that has entrusted you with their final arrangements.
            A standard North Carolina Healthcare Power of Attorney document may
            be &nbsp;
            <a
              className='underline text-green-700 hover:text-green-800'
              href='https://sosnc.gov/documents/forms/advance_healthcare_directives/health_care_power_of_attorney.pdf'
            >
              downloaded from the North Carolina Secretary of State website by
              clicking here.
            </a>
          </p>
          <p className='py-2'>
            You may also consult an attorney or ask your hospice provider if
            they have a standard short form Healthcare Power of Attorney
            document.
          </p>
          <p className='py-2'>
            Even though transitioning through hospice may begin the process of
            acceptance and working through grief, dealing with the death of a
            loved one is never easy. Your hospice provider can provide you with
            helpful guides and support group contacts to help assist you in
            coping and working through your grief.
          </p>
          <p className='py-2'>We're here for any questions you may have.</p>
          <div className='self-center my-4'>
            <Link to='/contact/'>
              <StyledButton>Talk to us</StyledButton>
            </Link>
          </div>
        </div>
        <Guarantee />

        {/* services block here */}
        <p className='mb-2 text-4xl text-gray-800 self-center'>Our Services</p>
        <div className='flex flex-wrap justify-center items-stretch -mx-2'>
          {services.map(({ node }) => (
            <Service
              title={node.frontmatter.title}
              slug={node.frontmatter.slug}
              url={node.frontmatter.path}
              key={node.frontmatter.path}
              icon={node.frontmatter.icon}
              price={node.frontmatter.price}
              blurb={node.frontmatter.blurb}
            ></Service>
          ))}
        </div>

        <div className='self-center my-3'>
          <Link to='/contact/'>
            <StyledButton>Find out more</StyledButton>
          </Link>
        </div>
      </div>
      <div className='w-full mt-6'> </div>

      <div className='flex flex-col mt-10 md:mt-16'>
        <p className='mb-2 text-3xl text-green-700 self-center pb-4 font-serif'>
          City of Oaks is committed to providing families with unsurpassed and
          professional service before, during, and after the time of loss.
        </p>
        <p className='py-3'>
          Our Funeral Home is located in Wake County, North Carolina; however,
          we provide our services to families throughout the entire state.
          Regardless of what County you live in, we can help your family with
          making simple, affordable funeral arrangements.
        </p>
        <p className='py-3'>
          We have an all-inclusive, low cost approach. The families we serve are
          typically educated, practical, environmentally conscious and
          economically sound decision makers. More frequently, families are
          deciding to bypass the lavish floral displays, catered receptions,
          expensive hearses and family cars or limousines that have often been
          associated with traditional American funerals of the past.
        </p>
        <p className='py-3'>
          We provide each family with a Licensed Funeral Director who will work
          with you from start to finish. Our team takes pride in being able to
          assist families with a wide range of cultures, religions, economic and
          other influencing factors. We provide compassionate care and
          understanding to families at the time of a pending death by making
          sure they are informed of what steps will follow and how we will be
          there to assist them through the entire process. Our Funeral Directors
          provide the reassuring voice that families rely on while going through
          a difficult time when a loved one passes away.
        </p>
        <div className='self-center my-3'>
          <Link to='/services/'>
            <StyledButton>Our services</StyledButton>
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            path
            icon
            price
            blurb
          }
          excerpt
        }
      }
    }
  }
`;

export default Hospice;
